import React, { useEffect, useState } from 'react';
import { node, bool } from 'prop-types';
import { Button } from '@chakra-ui/react';
import { exportComponentAsPNG } from 'react-component-export-image';

import { THE_BRIDE } from '@/constants';

const fileName = `kartu-akses-masuk-${THE_BRIDE}`.replace(' ', '-');

function ExportToPNG({ componentRef, autoDownload, ...rest }) {
  const [loading, setLoading] = useState(false);

  const onExport = () => {
    setLoading(true);
    exportComponentAsPNG(componentRef, { fileName: `${fileName}.png` });

    setTimeout(() => {
      setLoading(false);
    }, 4000);
  };

  useEffect(() => {
    if (autoDownload) {
      onExport();
    }
  }, []);

  return (
    <Button
      textTransform="uppercase"
      letterSpacing="2px"
      bgColor="bgPrimary"
      color="mainColorText"
      size="xs"
      className="animation-heart"
      isLoading={loading}
      onClick={onExport}
      {...rest}
    >
      Download E-Ticket
    </Button>
  );
}

ExportToPNG.propTypes = {
  autoDownload: bool.isRequired,
  componentRef: node,
};

export default ExportToPNG;
